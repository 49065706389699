<template>
<div class="ce-content">

    <div class="row ce-row">

        <!-- First col ------------------------>
        <div class="col-md-12">

            <!-- ce-content-inner ------------------------------>
            <div class="ce-content-inner">
                <div class="row app-list-wrap">
                    <div class="col-md-12 app-list-header">
                        <h3 class="ce-create-client-list-card title">App List</h3>
                        <div class="add-list-btn-wrap">
                            <button class="btn add-new ce-btn-create-role-submit" data-toggle="modal"
                                    data-target="#ce-create-newApp">
                                Create new App
                            </button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <!-- Add comment here -->
                        <div class="modal fade ce-create-app" id="ce-create-newApp" tabindex="-1" role="dialog"
                                 aria-labelledby="ce-create-app-modal" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                          <div class="scope-client-select">
                                            <label for="scope-client-select-id">Client: </label>
                                            <select v-model="client.client_type" name="" id="roles-client-select-id">
                                              <option v-for="data in clientTypeList" :key="data.id" :value="data.id">{{data.type}}</option>
                                            </select>
                                          </div>
                                            <div class="ce-create-client-card-item-wrap-content">
                                                <h4 class="ce-create-client-card-item-wrap-content-title">Name
                                                </h4>
                                                <div class="ce-create-client-card-item-wrap-input">
                                                    <input type="text" class="form-control" placeholder="Enter Name" v-model="client.name">
                                                </div>
                                            </div>

                                            <div class="ce-create-client-card-item-wrap-content">
                                                <h4 class="ce-create-client-card-item-wrap-content-title">Domain
                                                </h4>
                                                <div class="ce-create-client-card-item-wrap-input ce-domain">
                                                    <input type="text" class="form-control" placeholder="Enter Domain url" v-model="client.domain">
                                                </div>
                                            </div>

                                            <div class="ce-create-indepentant-client">
                                                <input type="checkbox" id="add-app-modal-independent" class="form-control" v-model="client.dependent"> <label for="add-app-modal-independent" >Independent</label>
                                            </div>

                                            <div class="ce-additional-url-cover" :class="{'ce-sso-dashno-nav': client.dependent}" v-if="client.dependent">
                                                <div class="ce-additional-url-inner">
                                                    <div class="ce-create-client-card-item-wrap-content">
                                                        <h4 class="ce-create-client-card-item-wrap-content-title">Login Url
                                                        </h4>
                                                        <div class="ce-create-client-card-item-wrap-input ce-domain">
                                                            <input type="text" class="form-control" placeholder="Enter Login url" v-model="client.login_url">
                                                        </div>
                                                    </div>
                                                    <div class="ce-create-client-card-item-wrap-content">
                                                        <h4 class="ce-create-client-card-item-wrap-content-title">Logout Url
                                                        </h4>
                                                        <div class="ce-create-client-card-item-wrap-input ce-domain">
                                                            <input type="text" class="form-control" placeholder="Enter Logout url" v-model="client.logout_url">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <div v-on:click="createClient(client)" class="ce-create-client-btn-wrap">
                                                    <button class="btn ce-btn-create-client-submit btn-primary" data-dismiss="modal">
                                                        Submit
                                                    </button>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="col-md-12">

                        <!-- Delete confirmation Modal -->
                        <div class="modal fade ce-client-delete" id="ce-client-delete" tabindex="-1" role="dialog" aria-labelledby="ce-client-delete-modal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="ce-client-delete-modal">Delete Client</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        Are you sure you want to delete <b class="ce-client-confirm">{{
                                            selected_client.name }} </b>?
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" data-dismiss="modal">Cancel
                                        </button>
                                        <button v-on:click="deleteClient" type="button" data-dismiss="modal" class="btn btn-danger">Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Delete confirmation Modal End -->

                        <!-- Edit client Modal -->
                        <div class="modal fade ce-client-delete" id="ce-client-edit" tabindex="-1" role="dialog" aria-labelledby="ce-client-delete-modal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="ce-client-edit-modal">Edit Client</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-md-4">
                                                Name
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    <input type="text" ref="name" name="name" v-model="selected_client.name" placeholder="Enter name!" class="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                Domain
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    <input type="text" ref="name" name="role_name" v-model="selected_client.redirect" placeholder="Enter Domain url" class="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ec-independent">
                                            <div class="col-md-4">
                                                <input type="checkbox" class="form-control" v-model="selected_client.dependent" id="ec-independent-id">
                                                <label for="ec-independent-id">Independent</label>
                                            </div>
                                        </div>
                                        <div class="ce-additional-url-cover" :class="{'ce-sso-dashno-nav': selected_client.dependent}" v-if="selected_client.dependent">
                                            <div class="ce-additional-url-inner">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        Login Url
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="form-group">
                                                            <input type="text" ref="name" name="role_name" v-model="selected_client.login_url" placeholder="Enter Login url" class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        Logout Url
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="form-group">
                                                            <input type="text" ref="name" name="role_name" v-model="selected_client.logout_url" placeholder="Enter Logout url" class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" data-dismiss="modal">Cancel
                                        </button>
                                        <button v-on:click="updateClient" type="button" data-dismiss="modal" class="btn btn-warning">Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Edit client Modal End -->
                        <!-- View client Modal -->
                        <div class="modal fade ce-client-view" id="ce-client-view-details" tabindex="-1" role="dialog" aria-labelledby="ce-client-view-modal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="ce-client-view-modal">View Client</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-md-4">
                                                Id
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    {{selected_client.id}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                Name
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    {{selected_client.name}}
                                                </div>
                                            </div>
                                        </div>
                                      <div class="row">
                                        <div class="col-md-4">
                                          Client Type
                                        </div>
                                        <div class="col-md-8">
                                          <div class="form-group">
                                            <p v-if="selected_client.personal_access_client===1">Personal Access Client</p>
                                            <p v-else-if="selected_client.password_client===1">Password Client</p>
                                            <p v-else>Client Credential</p>
                                          </div>
                                        </div>
                                      </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                Domain
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    {{selected_client.redirect}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                Site-Key
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    {{selected_client.site_key}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                Site Secret
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    {{selected_client.site_secret}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="clearData">Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- View client Modal End -->
                        <div class="ce-create-client-list-sec">
                            <div class="nothing-to-display" v-if="!clients.length">
                                No Clients to display !!
                            </div>
                            <div v-else class="table-responsive">
                                <table class="table table-bordered ce-client-card-table">
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Name</th>
                                            <th>Domain</th>
                                            <th>Site Key</th>
                                            <th style="width: 100px;">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(client,index) in clients" :key="index">
                                            <th>{{index+1}}</th>
                                            <td>{{client.name}}</td>
                                            <td>{{client.redirect}}</td>
                                            <td>{{client.site_key}}</td>
                                            <td>
                                                <ul class="ce-client-action">
                                                    <li v-on:click="selectClient(index)">
                                                        <button class="btn ce-client-edit" data-toggle="modal" data-target="#ce-client-edit"><i class="cts-settings cts_recipient_icon"
                                                        title="UPDATE CLIENT" aria-hidden="true"></i>
                                                        </button>
                                                    </li>
                                                    <li v-on:click="selectClient(index)">
                                                        <button class="btn ce-client-delete" data-toggle="modal" data-target="#ce-client-delete"><i class="cts-delete cts_recipient_icon"
                                                        title="DELETE CLIENT" aria-hidden="true"></i>
                                                        </button>
                                                    </li>
                                                        <li v-on:click="selectClient(index)">
                                                        <button class="btn ce-client-view" data-toggle="modal" data-target="#ce-client-view-details"><i class="cts-password-show cts_recipient_icon"
                                                        title="VIEW CLIENT DETAILS" aria-hidden="true"></i>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ce-content-inner End ------------------------>

        </div>
        <!-- First col End -------------------------------->

    </div>
</div>
</template>
<style>
.nothing-to-display {
    border: solid 1px #03ccff;
    padding: 15px 20px;
    font-size: 14px;
    border-radius: 5px;
}
.cts_recipient_icon{
    color:#2cc4ef;
    font-size:18px;
    cursor: pointer;
    margin: 7px;
}
.btn {
    padding: 0.rem 0.rem;
}
.ce-main-wrapper .ce-wrapper .ce-content .ce-content-inner .ce-create-client-list-sec .table-responsive table.ce-client-card-table thead th:last-child {
    text-align: left;
}
#ce-client-view-details {
    word-break: break-all;
}
#ce-client-view-details .modal-body .col-md-8 .form-group {
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    opacity: 0.9;
}
.cts-settings.cts_recipient_icon {
    font-weight: bold;
}
#ce-create-newApp .modal-content .modal-body .scope-client-select {
    width: 100%;
    margin-bottom: 15px;
}
#ce-create-newApp .modal-content .modal-body .scope-client-select label {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
}
#ce-create-newApp .modal-content .modal-body .scope-client-select select {
    width: 100%;
    background: #f8f9fa;
    font-weight: 600;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    border-radius: 0.25rem;
    border: 1px solid #00000033;
}
.ec-independent {
    margin-bottom: 20px;
}
.ec-independent .form-control {
    width: unset;
    display: inline-block;
    height: unset;
}
.ec-independent label {
    margin-left: 6px;
}
</style>

<script>
import Api from '../includes/Api';
import {
    mapState
} from 'vuex';

export default {
    data() {
        return {
            clients: [],
            selected_client: '',
            client: {
                client_type:'',
                name: '',
                domain: '',
                dependent: false,
                login_url: '',
                logout_url: ''
            },
            clientTypeList:[
              {id:1,type:'Client Credential'},
              {id:2,type:'Password Client'},
              {id:3,type:'Personal Access Client'}
            ]
        }
    },
    computed: mapState('User', ['user']),
    methods: {
        getClients() {
            let root = this;
            Api.get('/list-client').then((response) => {
                root.clients = response.data.data;
            });
        },
        deleteClient() {
            let root = this;
            Api.post('/delete-client', {
                id: root.selected_client.id
            }).then((response) => {
                if (response.data.status === "success") {
                    let index = root.clients.indexOf(root.selected_client);
                    root.clients.splice(index, 1);
                    root.selected_client = '';
                }
            });
        },
        selectClient(index) {
            this.selected_client = this.clients[index];
        },
        updateClient() {
            let root = this;
            Api.post('/update-client', root.selected_client).then((response) => {
                if (response.data.status === "success") {
                    root.clients.map(function (client, index) {
                        if (client.id === root.selected_client.id) {
                            root.clients[index] = root.selected_client;
                        }
                    });
                    root.selected_client = '';
                }
            });
        },
        createClient(data) {
            let root = this;
          if (root.client.client_type == ''){
            Api.alert("warning", "Client Type cannot be empty.");
          }
          else if (root.client.name == ''){
                Api.alert("warning", "Name cannot be empty.");
            }
            else if (root.client.domain == ''){
                Api.alert("warning", "Domain cannot be empty.");
            }
            else {
                Api.post('/create-client', {
                    data: data,
                    user_id: root.user.id
                }).then((response) => {
                    if (response.data.status === 'success') {
                        Api.alert('success', response.data.message);
                    }
                    this.getClients();
                });
            }
            root.clearData();
        },
        clearData() {
            this.selected_client = '';
            this.client = {
                name: '',
                domain: '',
                dependent: false,
                login_url: '',
                logout_url: ''
            }
        }
    },
    created() {
        this.getClients();
    }
}
</script>
